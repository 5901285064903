import React, {useRef, useState, useEffect} from 'react';
import {ScreenSet, ScreenItem} from "../Screen";
import './styles.css';
import axios from "axios";
import {apiUrl} from "../../Utils";


const biz = {
  collections: [
    {title: 'Космос', designs: [
        'Земля',
        'Венера',
        'Місяць',
        'Марс',
        'Сонце',
        'Меркурій',
        'Сонячная система'
      ]},
    {title: 'Люмінісцентні', designs: [
        'Земля',
        'Місяць',
        'Меркурій'
      ]},
    {title: 'Природа', designs: [
        'Парк Банф',
        'Прованс',
        'Гранд Каньйон',
        'Фіорди',
        'Балі',
        'Халльштатт',
        'Ніагарський водоспад'
      ]},
    {title: 'Пам\'ятки', designs: [
        'Міст Золота Брама',
        'Саграда Фамілія',
        'Ейфелева вежа',
        'Велика китайська стіна',
        'Колізей',
        'Тадж Махал',
        'Пам\'ятки світу',
        'Дубаї',
        'США'
      ]},
    {title: 'Мистецтво', designs: [
        'Крик',
        'Зоряна ніч',
        'Мона ліза',
        'Поцілунок',
        'Створення Адама',
        'Дівчина з перловою сережкою',
        'Враження. Схід сонця'
      ]},
    {title: 'Гори', designs: [
        'Маттергорн',
        'Браєс',
        'Національний парк Гранд-Тітон',
        'Рейне',
        'Фудзі',
        'Ванака',
        'Еверест'
      ]},
    {title: 'Україна', designs: [
        'Памір в Карпатах',
        'Площа Ринок у Львові',
        'Оперний театр в Одесі',
        'Кам\'янець-Подільський замок',
        'Київ'
      ]},
    {title: 'Криптовалюти', designs: [
        'Біткоїн',
        'Ріпл',
        'Ефіріум'
      ]},
    {title: 'Різдво', designs: [
        'Вечір перед Різдвом',
        'Один вдома',
        'Різдвяний настрій'
      ]},
    {title: 'Персоналізований', designs: [
        'Стандарт',
        'Love',
        'Серце'
      ]}
  ],
  sizes: [
    '30см',
    '40см',
    '50см'
  ],
  shipping: [
    'Без отправки',
    'UPS',
    'PostEx',
    'Консоль',
    'УкрПочта',
    'Новая Почта',
  ],
  platforms: [
    'PuzzleUp Store (US)',
    'PuzzleUp (USA)',
    'Indiegogo PuzzleUP (USD)',
    'Пазл Ап (USD)',
    'Переотправки Etsy PuzzleUP (USD)',
    'Переотправки Shopify PuzzleUP (USD)',
    'Переотправки опты PuzzleUP (USD)',
    'Puzzle UP Shopify (USD)',
    'Пазлы Украина',
    'Инстаграм Пазлы',
    'Indiegogo PuzzleUp'
  ]
};

const collectionsArray = biz.collections.map(it => it.title);

export default function PuzzleUp() {
  const [orderNum, setOrderNum] = useState();
  const [shipping, setShipping] = useState();
  const [platform, setPlatform] = useState();

  useEffect(() => {
    if (localStorage.getItem('as_pup_platform')) {
      setPlatform(localStorage.getItem('as_pup_platform'));
    }

  }, []);

  const [collection, setCollection] = useState(biz.collections[0].title);
  const [designs, setDesigns] = useState(biz.collections[0].designs);
  const [currentDesign, setCurrentDesign] = useState();
  const [size, setSize] = useState();
  const [comment, setComment] = useState();

  const plat = useRef();

  function setCol(cl) {
    const findCol = biz.collections.filter(it => it.title === cl);

    setCollection(cl);
    setDesigns(findCol[0].designs);
  }

  function OptionBlock(props) {
    const {name, action, values, check} = props;
    return (
      <div className='block-radio'>
        {values.map((item, index) => (
          <label key={index++}>
            <input type="radio" name={name} value={item}
                   checked={check === item} onChange={(e) => {
              action(e.target.value)
            }}/>
            <span>{item}</span>
          </label>
        ))}
      </div>
    );
  }


  const $form = useRef();
  const $res = useRef();

  function submit() {

    if (!validate()) {
      return alert("Заполните все поля!");
    }

    if (!orderNum) {
      let isExecuted = window.confirm("Этот заказ не будет добавлен в статистику из-за отсутствия номера. Хотите продолжить без него?");
      if(!isExecuted) {
        return true;
      }
    }

    if (orderNum) {
      const date = new Date();
      const data = {
        orderNum,
        signDate: `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`,
        platform: biz.platforms[platform-1],
        collection,
        product: 'PuzzleUp',
        design: currentDesign,
        size,
        shipping,
      }
      sendStatistics(data);
    }

    const res = `Круглый пазл\n` +
      `${currentDesign}\n` +
      `${size}\n` +
      `${comment ? comment : ''}\n` +
      `${shipping}\n`;



    copyToClipboard(res);

    $res.current.style.display = 'block';
    $res.current.querySelector('textarea').innerHTML = res;
    // $res.current.querySelector('#article').value = '';
    $form.current.style.display = 'none';


  }

  async function sendStatistics(data) {
    return await axios.post(apiUrl + '/stats/aspuzzleup',
      {...data},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('publicToken')}`
        }
      });
  }

  function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };



  function validate() {
    if (collection && size && shipping && currentDesign) {
      return true;
    }
    return false;
  }

  if (!platform) {
    return (
      <p>
        <h3>Выбери платформу</h3>
        <select size="1" ref={plat} name="platform">
          <option disabled>Выбери платформу</option>
          <option value="1">PuzzleUp Store (US)</option>
          <option value="2">PuzzleUp (USA)</option>
          <option value="3">Indiegogo PuzzleUP (USD)</option>
          <option value="4">Пазл Ап (USD)</option>
          <option value="5">Переотправки Etsy PuzzleUP (USD)</option>
          <option value="6">Переотправки Shopify PuzzleUP (USD)</option>
          <option value="7">Переотправки опты PuzzleUP (USD)</option>
          <option value="8">Puzzle UP Shopify (USD)</option>
          <option value="9">Пазлы Украина</option>
          <option value="10">Инстаграм Пазлы</option>
          <option value="11">Indiegogo PuzzleUp</option>
        </select>
        <button onClick={() => {
          localStorage.setItem('as_pup_platform', plat.current.value);
          setPlatform(localStorage.getItem('as_pup_platform'));
        }}>Применить
        </button>
      </p>
    )
  }

  return (
    <>
      <p>Платформа: <button onClick={() => {
        localStorage.removeItem('as_pup_platform')
        setPlatform(null);
      }}>{biz.platforms[platform-1]}</button></p>
      <div ref={$res} style={{display: 'none'}}>
        <textarea cols="30" rows="10"></textarea>
        <br/>
        {/*<input type='text' id='article'/>*/}
        {/*<br/>*/}
        {/*<button onClick={(e) => {*/}
        {/*  copyToClipboard(e.target.parentElement.querySelector('#article').value);*/}
        {/*}}>Копировать артикул*/}
        {/*</button>*/}
        <button onClick={() => {
          window.location.reload()
        }}>Финиш
        </button>
      </div>



      <div className='macro-form' ref={$form}>
        <ScreenSet submit={submit}>

          <ScreenItem>
            <h1 className='selectionTitle'>№ заказа</h1>
            <input onChange={(e) => {
              setOrderNum(e.target.value)
            }} value={orderNum} type="text"/>
            <button onClick={async () => {
              const text = await navigator.clipboard.readText();
              setOrderNum(text);
            }}>вставить
            </button>

            <h1 className='selectionTitle'>Размер</h1>
            <OptionBlock name='size' check={size}
                         values={biz.sizes}
                         action={setSize}/>
            <h1 className='selectionTitle'>Коллекция</h1>
            <OptionBlock name='collection' check={collection}
                         values={collectionsArray}
                         action={setCol}/>
            <h1 className='selectionTitle'>Дизайн</h1>
            <OptionBlock name='design' check={currentDesign}
                         values={designs}
                         action={setCurrentDesign}/>
          </ScreenItem>


          <ScreenItem>

            <h1 className='selectionTitle'>Комментарий</h1>
            <textarea cols="30" rows="10" onChange={(ev) => {
              setComment(ev.target.value);
            }}></textarea>
            <h1 className='selectionTitle'>Доставка</h1>
            <OptionBlock name='shipping' check={shipping}
                         values={biz.shipping}
                         action={setShipping}/>
          </ScreenItem>

        </ScreenSet>
      </div>
    </>
  );
}