import React, {useState} from 'react';
import AuthHttp from "../http/AuthHttp";

export const UserContext = React.createContext();

export const UserProvider = ({children}) => {

    const [user, setUser] = useState(null);

    function logoutAction() {
        AuthHttp.logout();
        setUser(null);
        localStorage.removeItem('publicToken');
    }

    const provideValues = {user, setUser, logoutAction};

    return (
        <UserContext.Provider value={provideValues}>
            {children}
        </UserContext.Provider>
    );
};