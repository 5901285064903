import React, {useRef, useState} from 'react';
import {ScreenSet, ScreenItem} from "../Screen";
import './styles.css';


const biz = {
    layers: {
        '2D': '2D',
        '3D': '3D'
    },
    maps: {
        'USA': 'USA',
        'Europe': 'EU',
        'Germany': 'DE',
        'Canada': 'CAN',
        'Ukraine': 'UKR'
    },
    sizes: {
        'S': 'S',
        'M': 'M',
        'L': 'L',
        'XL': 'XL'
    },
    types: {
        'Basic': 'BS',
        'Blank': 'BL'
    },
    colors2d: {
        "Дуб": "OK",
        "Светлая": "LT",
        "Темн.Орех": "WA",
        "Терра": "TE",
        "Черный": "BK",
        "Белый": "WH",
        "Синий": "BU",
        "Пробка": "CR",
        "Пр-ка черн.": "CRBK",
    },
    colors3d: {
        "Многосл.": "MC",
        "Дуб": "OK",
        "Светлая": "LT",
        "Темн.Орех": "WA",
        "Терра": "TE",
        "Midnight": "MI",
        "Azure": "AZ",
        "Nordik": "NO",
        "Berry": "BE",
        "Cappuccino": "CA",
        "Aqua": "AQ",
        "Indie": "IN",
        "Country": "CO",
        "Urban": "UR",
        "Bubble-gum": "BG",
        "Boho": "BH",
        "Mystery": "MY",
        "Seaside": "SE",
        "Jungle": "JU",
        "Waterpark": "WT",
        "Rustic": "RU",
        "Suburbs": "SU",
        "Rodeo": "RO",
        "Festival": "FE",
        "Cruise": "CRU",
        "Norway": "NR",
        "Physical": "PH"
    },
    extra: {
        "Alaska": "ALK",
        "LED": "LD"
    }
};

export default function MapsRegional() {
    const [mapLayer, setMapLayer] = useState('');
    const [mapTitle, setMapTitle] = useState('');
    const [mapSize, setMapSize] = useState('');
    const [mapType, setMapType] = useState('');
    const [mapColor, setMapColor] = useState('');
    const [mapLang, setMapLang] = useState('На английском');
    const [mapExtra, setMapExtra] = useState({al: false, led: false});

    function OptionBlock(props) {
        const {name, action, values, check} = props;
        return (
            <div className='block-radio'>
                {values.map((item, index) => (
                    <label key={index++}>
                        <input type="radio" name={name} value={item} checked={check === item} onChange={(e) => {
                            action(e.target.value)
                        }}/>
                        <span>{item}</span>
                    </label>
                ))}
            </div>
        );
    }

    function copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const $form = useRef();
    const $result = useRef();


    function validate() {
        if(!mapLayer) {
            return false;
        }
        if(!mapTitle) {
            return false;
        }
        if(!mapSize) {
            return false;
        }
        if(mapTitle !== 'USA' && mapTitle !== 'Ukraine') {
            if(!mapType) {
                return false;
            }
        }
        if(!mapColor) {
            return false;
        }
        return true;
    }
    function submit(e) {
        e.preventDefault();
        const article = [];

        if(!validate()) {
            return alert('Заполните все поля!');
        }

        article.push(biz.maps[mapTitle]);
        article.push(biz.layers[mapLayer]);
        article.push(biz.sizes[mapSize]);
        if(mapTitle !== 'USA' && mapTitle !== 'Ukraine') {
            article.push(biz.types[mapType]);
        }
        if(mapLayer === '2D') {
            article.push(biz.colors2d[mapColor]);
        } else if(mapLayer === '3D') {
            article.push(biz.colors3d[mapColor]);
        }
        let extra = mapExtra.al ? article.push(biz.extra['Alaska']) : '';
        extra = mapExtra.led ? article.push(biz.extra['LED']) : '';

        const sign = `Карта ${mapTitle}\n` +
            `${mapLayer === '2D' ? "Однослойная" : "Многослойная"}\n` +
            `Размер - ${mapSize}\n` +
            `Цвет - ${mapColor}\n` +
            `${mapLang}\n` +
            `${mapType ? 'Тип - ' + mapType + '\n' : ''}` +
            `${mapExtra.al ? `Alaska\n` : ''}` +
            `${mapExtra.led ? `LED\n` : ''}`;
        $form.current.style.display = 'none';
        $result.current.style.display = 'block';

        $result.current.querySelector('textarea').innerHTML = sign;
        $result.current.querySelector('input').value = article.join('');

        copyToClipboard(article.join(''));
    }

    function map_size_dep() {
        if (mapTitle === 'USA') {
            return ['M', 'L'];
        } else if (mapTitle === 'Europe') {
            return ['S', 'M', 'L'];
        } else if (mapTitle === 'Germany') {
            return ['S', 'M', 'L'];
        } else if (mapTitle === 'Canada') {
            return ['S', 'M', 'L'];
        } else if (mapTitle === 'Ukraine') {
            return ['M', 'L', 'XL'];
        }
        return ['M'];
    }

    function map_type_dep() {
        if (mapTitle === 'USA') {
            return [];
        } else if (mapTitle === 'Europe') {
            return ['Basic', 'Blank'];
        } else if (mapTitle === 'Germany') {
            return ['Basic', 'Blank'];
        } else if (mapTitle === 'Canada') {
            return ['Basic', 'Blank'];
        } else if (mapTitle === 'Ukraine') {
            return [];
        }
        return ['Basic'];
    }

    function lay_color_dep() {
        if(mapLayer === '2D') {
            return Object.keys(biz.colors2d);
        } else if (mapLayer === '3D') {
            return Object.keys(biz.colors3d);
        }
        return [null];
    }

    function ukrMap_ukrLang_dep(val) {
        if(val === 'Ukraine') {
            setMapLang('На украинском');
        }
    }

    return (
        <>
            <div ref={$result} style={{display: 'none'}}>
                <input id='art' type="text"/>
                <br/>
                <button onClick={() => {
                    copyToClipboard($result.current.querySelector('#art').value);
                }} >Copy</button>
                <br/>
                <textarea name="" id="" cols="30" rows="10">

                </textarea>
                <br/>
                <button onClick={() => {
                    copyToClipboard($result.current.querySelector('textarea').value);
                }} >Copy</button>
                <br/>
                <button onClick={() => {
                    window.location.reload();
                }}>Restart</button>
                <div style={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                    <button onClick={() => copyToClipboard('UPS')}>UPS</button>
                    <button onClick={() => copyToClipboard('PostEx')}>PostEx</button>
                    <button onClick={() => copyToClipboard('Консоль')}>Консоль</button>
                    <button onClick={() => copyToClipboard('УкрПочта')}>УкрПочта</button>
                    <button onClick={() => copyToClipboard('УкрПочта')}>Нова Почта</button>
                </div>
                <br/>
                <br/>
                <div style={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                    <button onClick={() => copyToClipboard('На английском')}>На английском</button>
                    <button onClick={() => copyToClipboard('На русском')}>На русском</button>
                    <button onClick={() => copyToClipboard('На украинском')}>На украинском</button>
                    <button onClick={() => copyToClipboard('На французком')}>На французком</button>
                    <button onClick={() => copyToClipboard('На испанском')}>На испанском</button>
                </div>
                <br/>
                <div style={{display: 'flex', flexDirection: 'column', width: '150px'}}>
                    <button onClick={() => copyToClipboard('С точками')}>С точками</button>
                    <button onClick={() => copyToClipboard('Без точек')}>Без точек</button>
                    <button onClick={() => copyToClipboard('Провод слева')}>Провод слева</button>
                    <button onClick={() => copyToClipboard('Провод справа')}>Провод справа</button>
                </div>
            </div>
            <div className='macro-form' ref={$form}>

                <ScreenSet submit={submit}>
                    <ScreenItem>
                        <h1 className='selectionTitle'>Дизайн</h1>
                        <OptionBlock name='lay' check={mapLayer} values={Object.keys(biz.layers)}
                                     action={setMapLayer}/>

                        <h1 className='selectionTitle'>Карта</h1>
                        <OptionBlock name='map' check={mapTitle} values={Object.keys(biz.maps)}
                                     action={(val) => {
                                         ukrMap_ukrLang_dep(val);
                                         setMapTitle(val);
                                     }}/>

                        <h1 className='selectionTitle'>Размер</h1>
                        <OptionBlock name='sizes' check={mapSize} values={map_size_dep()} action={setMapSize}/>

                        <h1 className='selectionTitle'>Тип</h1>
                        <OptionBlock name='type' check={mapType} values={map_type_dep()} action={setMapType}/>
                        <br/>
                        {mapTitle === 'USA' ? <label><input type='checkbox' value={mapExtra.al} onClick={() => {setMapExtra({...mapExtra, al: !mapExtra.al})}}/> <span>Alaska</span></label> : ''}
                        {mapTitle === 'USA' || mapTitle === 'Ukraine' ? <><br/><label><input type='checkbox' value={mapExtra.led} onClick={() => {setMapExtra({...mapExtra, led: !mapExtra.led})}}/> <span>LED</span></label></> : ''}
                    </ScreenItem>
                    <ScreenItem>
                        <h1 className='selectionTitle'>Язык карты</h1>
                        <br/>
                        <select name="select" value={mapLang} onChange={setMapLang}>
                            <option value="На английском">На английском</option>
                            <option value="На русском">На русском</option>
                            <option value="На украинском">На украинском</option>
                            <option value="На французком">На французком</option>
                            <option value="На испанском">На испанском</option>
                        </select>
                        <br/>
                        <h1 className='selectionTitle'>Дизайн</h1>
                        <OptionBlock name='color' check={mapColor} values={lay_color_dep()}
                                     action={setMapColor}/>
                    </ScreenItem>
                </ScreenSet>
            </div>
        </>
    );

}