import React, {useEffect, useState} from "react";

export function ScreenSet(props) {

    const [screenNum, setScreen] = useState(1);
    const [lastScreen, setLastScreen] = useState(1);

    const prevScreen = () => {
        if(screenNum > 1) {
            setScreen((prev) => prev - 1);
        }
    };
    const nextScreen = () => {
        if(screenNum < lastScreen) {
            setScreen((prev) => prev + 1);
        }
    }

    useEffect(() => {
        if(!props.children.length) {
            return setLastScreen(1);
        }
        return setLastScreen(props.children.length);
    }, []);


    return (
        <div className='screen-set'>
            <div className="btn-set">
                <button onClick={prevScreen}>Назад</button>
                {screenNum === lastScreen ? <button className='ready' onClick={props.submit}>Готово</button> : <button onClick={nextScreen}>Дальше</button>}
            </div>
            {lastScreen === 1 ? props.children : props.children[screenNum - 1]}
        </div>
    );
}

export function ScreenItem(props) {
    return (
        <div className='screen-item'>{props.children}</div>
    )
}

