import React from 'react';
import axios from 'axios';
import {apiUrl} from "../../../Utils";

const StatsBlockItem = ({img, title, loadLink}) => {
  console.log(apiUrl + loadLink)
  async function download(link) {
    const res = await axios.get(apiUrl + link, {});
    const downloadCSV = function(data){
      var MIME_TYPE = "text/csv";

      var blob = new Blob([data], {type: MIME_TYPE});
      window.location.href = window.URL.createObjectURL(blob);
    };
    downloadCSV(res.data);
  }

  return (
    <div>
      <div className='stats-item'>
        <div className='img'>
          <img src={img} alt=""/>
        </div>
        <h3>{title}</h3>
        <button onClick={() => download(loadLink)}>Скачать csv</button>
      </div>
    </div>
  );
};

export default StatsBlockItem;