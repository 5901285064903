import React, {useContext, useEffect, useState} from 'react';

import LoginPage from "./pages/LoginPage";
import Controller from "./pages/Controller";

import {UserContext} from "./context/UserContext";
import AuthHttp from './http/AuthHttp';

import './pages/styles/styles.css';


const App = () => {
    const [clientLoad, setClientLoad] = useState(true);

    const Auth = useContext(UserContext);

    useEffect(() => {

        async function tryRefresh() {
            try {
                const userData = await AuthHttp.refresh();
                Auth.setUser(userData);
            } catch (e) {
                localStorage.removeItem('publicToken');
                Auth.setUser(null);
            }
        }

        if (localStorage.getItem('publicToken')) {
            tryRefresh();
        }

        setClientLoad(false);
    }, []);


    if (!clientLoad) {
        if (Auth.user) {
            return <Controller/>;
        } else {
            return <LoginPage/>;
        }
    }

    return (
        <>
        </>
    );
};

export default App;
