import React from 'react';
import {openApp} from "../../../applications/AppExecutor";

const AppsBlockItem = ({appId, img, title, description}) => {
    return (
        <div className='apps-item' onClick={() => {openApp(appId)}}>
            <div className='row'>
                <img src={img} alt=""/>
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
};

export default AppsBlockItem;