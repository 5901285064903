import React, {useEffect, useRef, useState} from 'react';

import axios from 'axios';

import {ScreenSet, ScreenItem} from "../Screen";

import './styles.css';
import $infoGraphic from './info.png';
import {apiUrl} from "../../Utils";

const business = {
    data: {
        designs: [
            'Дино синий',
            'Лес',
            'Борд Транспорт',
            'Борд Сафарі',
            'Борд Геометрія Пастель',
            'Борд Геометрія Веселка',
            'Фігурки',
            'Фігурки 2.0',
            'Метелик',
            'Планети',
            'Космонавт',
            'Пасхальний Кролик',
            'Компас',
            'Єдиноріг та веселка',
            'Машинки',
            'Сафари',
            'Океан',
            'Радуга',
            'Облачко',
            'Кораблик',
            'Прямоугольник',
            'Дино розовый',
            'Дино зеленый',
            'Принцесса',
            'Борд лес',
            'Борд динозавры',
            'Борд солнечная система',

        ],
        extra: [],
        shipping: [
            'Без отправки',
            'UPS',
            'PostEx',
            'Консоль',
            'УкрПочта',
            'Новая Почта',
        ],
        figures: [
            'Empty',
            'Fox',
            'Raccoon',
            'Hedgehog',
            'Owl',
            'Bear',
            'Bunny',
            'Wolf',
            'Deer',
            'Squirrel',
            //new
            'Butterfly',
            'Rainbow',
            'Flower',
            'Donut',
            'Sun',
            'Cloud',
            'Greendino',
            'Orangedino',
            'Panda',
            'Bunnyface',
            'Bearface',
            'FoxFace',
            'Car',
            'Helicopter',
            'Yellowrocket',
            'Bluerocket',
            'Planet',
            'Star',
            'Crown',
            'Unicorn ',
            'Heart',
            'Swan',
            'Mountain',
            'Teepee',
            'Ladybug',
        ],
        fig: [
            "empty",
            "butterfly",
            "rainbow ",
            "flower",
            "donut",
            "sun",
            "cloud",
            "greendino",
            "orangedino",
            "panda ",
            "bunnyface",
            "bearface",
            "foxface",
            "car",
            "helicopter",
            "yellowrocket",
            "bluerocket",
            "planet",
            "star",
            "crown",
            "unicorn",
            "heart",
            "swan",
            "mountain ",
            "teepee",
            "ladybug",
        ]
    },
    article: {
        designs: {
            'Дино синий': 'PP-01',
            'Планеты': 'PP-02',
            'Ракеты': 'PP-03',
            'Единорожек': 'PP-04',
            'Американский футбол': 'PP-05',
            'Бейсбол': 'PP-06',
            'Роботы': 'PP-07',
            'Ежик': 'PP-08',
            'Коала': 'PP-09',
            'Слоники': 'PP-10',
            'Панда': 'PP-11',
            'Дино космос': 'PP-12',
            'Дино воздух': 'PP-13',
            'Дино вода': 'PP-14',
            'Машинки': 'PP-15',
            'Сафари': 'PP-16',
            'Океан': 'PP-17',
            'Радуга': 'PP-18',
            'Облачко': 'PF-01',
            'Кораблик': 'PF-02',
            'Прямоугольник': 'PP-21',
            'Дино розовый': 'PP-22',
            'Дино зеленый': 'PP-23',
            'Принцесса': 'PP-24',
            'Борд динозавры': 'PP-25',
            'Борд солнечная система': 'PP-26',
        }
    },
    platforms: [
        'Etsy ChildUniverse',
        'Shopify ChildUniverse',
        'Amazon ChildUniverse',
        'eBay ChildUniverse',
        'Украина ChildUniverse',
        'Опты ChildUniverse',
        'Переотправки Etsy ChildUniverse',
        'Переотправки Shopify ChildUniverse',
        'Переотправки Amazon ChildUniverse',
        'Переотправки eBay ChildUniverse'
    ]
};

export default function Puzzle() {
    const [puzzleDesign, setPuzzleDesign] = useState('');
    const [puzzleName, setPuzzleName] = useState('');

    const [puzzleOrderNum, setPuzzleOrderNum] = useState('');

    const [puzzleEngrave, setPuzzleEngrave] = useState('');
    const [puzzleColor, setPuzzleColor] = useState(1);

    const [puzzleFigures, setPuzzleFigures] = useState({left: 0, right: 0});
    const [puzzleExtraText, setPuzzleExtraText] = useState(['']);
    const [puzzleShipping, setPuzzleShipping] = useState('');

    const plat = useRef();

    const [showResult, setShowResult] = useState(false);

    const [cbExtra, setCbExtra] = useState({acryllic: false, dowel: true, present: false, note: false});

    const [platform, setPlatform] = useState(1);

    useEffect(() => {
        if (localStorage.getItem('as_cu_platform')) {
            setPlatform(localStorage.getItem('as_cu_platform') - 1);
        } else {
            localStorage.setItem('as_cu_platform', 0);
        }
    }, []);

    function OptionBlock(props) {
        const {name, action, values, check} = props;
        return (
            <div className='block-radio'>
                {values.map((item, index) => (
                    <label key={index++}>
                        <input type="radio" name={name} value={item} checked={check === item} onChange={(e) => {
                            action(e.target.value)
                        }}/>
                        <span>{item}</span>
                    </label>
                ))}
            </div>
        );
    }


    const $form = useRef();
    const $res = useRef();

    function submit() {


        if (!validate()) {
            return alert("Заполните все поля!");
        }
        if (puzzleName.toString().length < 3 || puzzleName.toString().length > 9) {
            return alert("Имя пазла должно быть от 2 до 9 сммволов!");
        }

        if (!puzzleOrderNum) {
            alert('Вы не ввели номер заказа! Данные в этой итерации не будут отправляться в статистику.');
        }

        const articleArr = [];
        articleArr.push(business.article.designs[puzzleDesign]);
        articleArr.push(puzzleName.toString().length);
        if (cbExtra.dowel) {
            articleArr.push('PG');
        }
        if (puzzleEngrave) {
            articleArr.push('TB');
        }
        if (cbExtra.acryllic) {
            articleArr.push('AK');
        }
        const article = articleArr.join('');

        let realDesign;
        if (puzzleDesign == 'Лес' || puzzleDesign == 'Прямоугольник' || puzzleDesign == 'Борд лес' || puzzleDesign == 'Борд солнечная система' || puzzleDesign == 'Борд динозавры') {
            realDesign = puzzleDesign;
        } else {
            realDesign = 'печать ' + puzzleDesign;
        }

        if (puzzleOrderNum) {
            const date = new Date();
            const data = {
                orderNum: puzzleOrderNum,
                signDate: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
                platform: business.platforms[platform - 1],
                product: 'Child Puzzle',
                design: puzzleDesign,
                name: puzzleName,
                symbolsCount: puzzleName.replace(' ', '').length,
                backEngrave: puzzleEngrave ? true : false,
                acrylicStand: cbExtra.acryllic,
                dowels: cbExtra.dowel,
                giftWrap: cbExtra.present,
                note: cbExtra.note,
                shipping: puzzleShipping
            }
            sendStatistics(data);
        }

        let figures = '';
        if (puzzleDesign == 'Лес') {
            figures = `Фигурки-${business.data.figures[puzzleFigures.left]}` +
                `|${business.data.figures[puzzleFigures.right]}\n`;
        } else if (puzzleDesign == 'Фігурки') {
            figures = `Фигурки-${business.data.fig[puzzleFigures.left]}` +
                `|${business.data.fig[puzzleFigures.right]}\n`;
        } else if (puzzleDesign == 'Фігурки 2.0') {
            figures = `Фігурки- figure${puzzleFigures.left}` +
                `|figure${puzzleFigures.right}\n`;
        }

        let res;

        if (puzzleDesign == 'Фігурки 2.0') {

            res = `\\-/Пазл ${realDesign}\n` +
                `${figures}` +
                `Імʼя - ${puzzleName}\n` +
                `${puzzleEngrave ? 'Гравіювання ззаду - \n' + '[' + puzzleEngrave + ']' : ''}\n` +
                `${cbExtra.acryllic ? 'Акрилові підставки' : ''}\n${cbExtra.dowel ? 'Шканти' : ''}\n` +
                `Колір ${puzzleColor}\n` +
                `{${cbExtra.present ? 'Подарункова уп.\n' : ''}${cbExtra.note ? 'Записка\n' : ''}` +
                `${puzzleExtraText}}\n\n${puzzleShipping}`;

        } else {
            res = `\\-/Пазл ${realDesign}\n` +
                `${figures}` +
                `Имя - ${puzzleName}\n` +
                `${puzzleEngrave ? 'Гравировка сзади - \n' + '[' + puzzleEngrave + ']' : ''}\n` +
                `${cbExtra.acryllic ? 'Акрилловые подставки' : ''}\n${cbExtra.dowel ? 'Шканты' : ''}\n` +
                `Цвет ${puzzleColor}\n` +
                `{${cbExtra.present ? 'Подарочная упак.\n' : ''}${cbExtra.note ? 'Записка\n' : ''}` +
                `${puzzleExtraText}}\n\n${puzzleShipping}`;
        }


        copyToClipboard(res);

        $res.current.style.display = 'block';
        $res.current.querySelector('textarea').innerHTML = res;
        $res.current.querySelector('#article').value = article;
        $form.current.style.display = 'none';


    }

    async function sendStatistics(data) {
        return await axios.post(apiUrl + '/stats/aschilduniverse', {...data}, {headers: {Authorization: `Bearer ${localStorage.getItem('publicToken')}`}});
    }

    function copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    function validate() {
        if (puzzleDesign && puzzleName && puzzleColor && puzzleShipping) {
            return true;
        }
        return false;
    }

    if (!platform) {
        return (
            <p>
                <h3>Выбери платформу</h3>
                <select size="1" ref={plat} name="platform">
                    <option disabled>Выбери платформу</option>
                    <option value="1" selected>Etsy ChildUniverse</option>
                    <option value="2">Shopify ChildUniverse</option>
                    <option value="3">Amazon ChildUniverse</option>
                    <option value="4">eBay ChildUniverse</option>
                    <option value="5">Украина ChildUniverse</option>
                    <option value="6">Опты ChildUniverse</option>
                    <option value="7">Переотправки Etsy ChildUniverse</option>
                    <option value="8">Переотправки Shopify ChildUniverse</option>
                    <option value="9">Переотправки Amazon ChildUniverse</option>
                    <option value="10">Переотправки eBay ChildUniverse</option>
                </select>
                <button onClick={() => {
                    localStorage.setItem('as_cu_platform', plat.current.value);
                    setPlatform(localStorage.getItem('as_cu_platform'));
                }}>Применить
                </button>
            </p>
        )
    }

    return (
        <>
            <p>Платформа: <button onClick={() => {
                localStorage.removeItem('as_cu_platform')
                setPlatform(null);
            }}>{business.platforms[platform - 1]}</button></p>
            <div ref={$res} style={{display: 'none'}}>
                <textarea cols="30" rows="10"></textarea>
                <br/>
                <input type='text' id='article'/>
                <br/>
                <button onClick={(e) => {
                    copyToClipboard(e.target.parentElement.querySelector('#article').value);
                }}>Копировать артикул
                </button>
                <button onClick={() => {
                    window.location.reload()
                }}>Финиш
                </button>
            </div>
            <div className='macro-form' ref={$form}>
                {puzzleDesign} / {puzzleName} / {puzzleEngrave} / {puzzleColor}
                <ScreenSet submit={submit}>
                    <ScreenItem>
                        <h1 className='selectionTitle'>№ заказа</h1>
                        <input onChange={(e) => {
                            setPuzzleOrderNum(e.target.value)
                        }} value={puzzleOrderNum} type="text"/>
                        <button onClick={async () => {
                            const text = await navigator.clipboard.readText();
                            setPuzzleOrderNum(text);
                        }}>вставить
                        </button>
                        <h1 className='selectionTitle'>Дизайн</h1>
                        <OptionBlock name='design' check={puzzleDesign} values={business.data.designs}
                                     action={setPuzzleDesign}/>
                    </ScreenItem>
                    <ScreenItem>
                        <h1 className='selectionTitle'>Имя</h1>
                        <input className='basic-input' autoFocus value={puzzleName}
                               onChange={(e) => setPuzzleName(e.target.value)}/>
                        {puzzleDesign == 'Лес' ? <>
                            <h1 className='selectionTitle'>Фигурки</h1>
                            <span>Слева</span>
                            <select size="1" name="left-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, left: e.target.value})
                            }}>
                                <option disabled>Левая фигурка</option>
                                <option value="0">Empty</option>
                                <option value="1">1 Fox</option>
                                <option value="2">2 Raccoon</option>
                                <option value="3">3 Hedgehog</option>
                                <option value="4">4 Owl</option>
                                <option value="5">5 Bear</option>
                                <option value="6">6 Bunny</option>
                                <option value="7">7 Wolf</option>
                                <option value="8">8 Deer</option>
                                <option value="9">9 Squirrel</option>
                            </select>
                            <br/>
                            <span>Справа</span>
                            <select size="1" name="right-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, right: e.target.value})
                            }}>
                                <option disabled>Правая фигурка</option>
                                <option value="0">Empty</option>
                                <option value="1">1 Fox</option>
                                <option value="2">2 Raccoon</option>
                                <option value="3">3 Hedgehog</option>
                                <option value="4">4 Owl</option>
                                <option value="5">5 Bear</option>
                                <option value="6">6 Bunny</option>
                                <option value="7">7 Wolf</option>
                                <option value="8">8 Deer</option>
                                <option value="9">9 Squirrel</option>
                            </select>
                        </> : <></>}

                        {puzzleDesign == 'Фігурки' ? <>
                            <h1 className='selectionTitle'>Фигурки</h1>
                            <span>Слева</span>
                            <select size="1" name="left-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, left: e.target.value})
                            }}>
                                <option disabled>Левая фигурка</option>

                                <option value="0">0 empty</option>
                                <option value="1">1 butterfly</option>
                                <option value="2">2 rainbow</option>
                                <option value="3">3 flower</option>
                                <option value="4">4 donut</option>
                                <option value="5">5 sun</option>
                                <option value="6">6 cloud</option>
                                <option value="7">7 greendino</option>
                                <option value="8">8 orangedino</option>
                                <option value="9">9 panda</option>
                                <option value="10">10 bunnyface</option>
                                <option value="11">11 bearface</option>
                                <option value="12">12 foxface</option>
                                <option value="13">13 car</option>
                                <option value="14">14 helicopter</option>
                                <option value="15">15 yellowrocket</option>
                                <option value="16">16 bluerocket</option>
                                <option value="17">17 planet</option>
                                <option value="18">18 star</option>
                                <option value="19">19 crown</option>
                                <option value="20">20 unicorn</option>
                                <option value="21">21 heart</option>
                                <option value="22">22 swan</option>
                                <option value="23">23 mountain</option>
                                <option value="24">24 teepee</option>
                                <option value="25">25 ladybug</option>
                            </select>
                            <br/>
                            <span>Справа</span>
                            <select size="1" name="right-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, right: e.target.value})
                            }}>
                                <option disabled>Правая фигурка</option>
                                <option value="0">0 empty</option>
                                <option value="1">1 butterfly</option>
                                <option value="2">2 rainbow</option>
                                <option value="3">3 flower</option>
                                <option value="4">4 donut</option>
                                <option value="5">5 sun</option>
                                <option value="6">6 cloud</option>
                                <option value="7">7 greendino</option>
                                <option value="8">8 orangedino</option>
                                <option value="9">9 panda</option>
                                <option value="10">10 bunnyface</option>
                                <option value="11">11 bearface</option>
                                <option value="12">12 foxface</option>
                                <option value="13">13 car</option>
                                <option value="14">14 helicopter</option>
                                <option value="15">15 yellowrocket</option>
                                <option value="16">16 bluerocket</option>
                                <option value="17">17 planet</option>
                                <option value="18">18 star</option>
                                <option value="19">19 crown</option>
                                <option value="20">20 unicorn</option>
                                <option value="21">21 heart</option>
                                <option value="22">22 swan</option>
                                <option value="23">23 mountain</option>
                                <option value="24">24 teepee</option>
                                <option value="25">25 ladybug</option>
                            </select>
                        </> : <></>}

                        {puzzleDesign == 'Фігурки 2.0' ? <>
                            <h1 className='selectionTitle'>Фигурки</h1>
                            <span>Слева</span>
                            <select size="1" name="left-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, left: e.target.value})
                            }}>
                                <option disabled>Левая фигурка</option>

                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                            </select>
                            <br/>
                            <span>Справа</span>
                            <select size="1" name="right-figure" autoFocus onChange={(e) => {
                                setPuzzleFigures({...puzzleFigures, right: e.target.value})
                            }}>
                                <option disabled>Правая фигурка</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                            </select>
                        </> : <></>}

                        <h1 className='selectionTitle'>Гравировка сзади</h1>
                        <input className='basic-input' autoFocus value={puzzleEngrave}
                               onChange={(e) => setPuzzleEngrave(e.target.value)}/>
                        <h1 className='selectionTitle'>Цвет</h1>
                        {puzzleDesign === 'Фігурки 2.0' ? (
                                <input type={'text'} placeholder={"Color code"}
                                       onChange={(e) => setPuzzleColor(e.target.value)}/>
                            ) :
                            <select size="1" name="color" autoFocus onChange={(e) => setPuzzleColor(e.target.value)}>
                                <option disabled>Выберите цвет</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                            </select>}
                        {/*<a href='#'>Инфографика*/}
                        {/*    <div><img src={$infoGraphic} width='300px'/></div>*/}
                        {/*</a>*/}
                        <br/>
                        <label style={{display: "block"}}>
                            <input style={{marginRight: '3px'}}
                                   type='checkbox'
                                   checked={cbExtra.acryllic}
                                   onChange={e => setCbExtra({...cbExtra, acryllic: !cbExtra.acryllic})}/>
                            <span>Акриловые подставки</span>
                        </label>
                        <label style={{display: "block"}}>
                            <input style={{marginRight: '3px'}}
                                   type='checkbox'
                                   checked={cbExtra.dowel}
                                   onChange={e => setCbExtra({...cbExtra, dowel: !cbExtra.dowel})}
                            />
                            <span>Шканты</span>
                        </label>
                        <label style={{display: "block"}}>
                            <input style={{marginRight: '3px'}}
                                   type='checkbox'
                                   onChange={e => setCbExtra({...cbExtra, present: !cbExtra.present})}
                            />
                            <span>Подарочная упаковка</span>
                        </label>
                        <label style={{display: "block"}}>
                            <input style={{marginRight: '3px'}}
                                   type='checkbox'
                                   onChange={e => setCbExtra({...cbExtra, note: !cbExtra.note})}
                            />
                            <span>Записка</span>
                        </label>

                    </ScreenItem>
                    <ScreenItem>
                        <h1 className='selectionTitle'>Дополнительно</h1>
                        <textarea cols="30" rows="10" value={puzzleExtraText}
                                  onChange={e => setPuzzleExtraText(e.target.value)}></textarea>
                        <h1 className='selectionTitle'>Доставка</h1>
                        <OptionBlock name='shipping' check={puzzleShipping} values={business.data.shipping}
                                     action={setPuzzleShipping}/>
                    </ScreenItem>
                </ScreenSet>
            </div>
        </>


    );

}
