import './styles/main.css';

import React, {useContext} from 'react';
import {BrowserRouter as Router, Switch, Route, Link, Redirect, useParams} from "react-router-dom";

import {UserContext} from "../context/UserContext";

import ChildPuzzleApp from '../applications/childpuzzle/Puzzle';
import RegioMapsApp from '../applications/regiomaps/MapsRegional';

import MainPage from './MainPage';
import StatsPage from "./StatsPage";
import PuzzleUp from "../applications/puzzleup/PuzzleUp";

const Controller = () => {
    const auth = useContext(UserContext);

    const roles = [
        'Super',
        'Manager'
    ];

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <MainPage/>
                </Route>

                {/*<Route path="/stats">*/}
                {/*    <StatsPage/>*/}
                {/*</Route>*/}

                <Route path="/apps/regiomaps">
                    <RegioMapsApp/>
                </Route>
                <Route path="/apps/childpuzzle">
                    <ChildPuzzleApp/>
                </Route>
                <Route path="/apps/puzzleup">
                    <PuzzleUp/>
                </Route>


                {/* <Route path="*">
                    <Redirect to='/'/>
                </Route> */}

            </Switch>
        </Router>
    );
};

export default Controller;