import React from 'react';

const ContainerHeader = (props) => {
    return (
        <div className='container' style={{width: '70%', marginTop: '50px'}}>
            <h2 className='container-title'>
                {props.children}
            </h2>
        </div>
    );
};



export default ContainerHeader;