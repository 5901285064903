import React, {useContext, useRef} from 'react';
import {UserContext} from "../context/UserContext";
import AuthHttp from "../http/AuthHttp";

import styles from './styles/Login.module.css';
import logo from './images/etw-logo-495057.svg';

const LoginPage = () => {

    const $login = useRef();
    const $password = useRef();

    const $logo = useRef();
    const $status = useRef();

    const Auth = useContext(UserContext);

    async function tryToAuth(e) {
        e.preventDefault();
        try {
            $logo.current.classList.add(styles.spinLogo);
            const userData = await AuthHttp.login($login.current.value, $password.current.value);
            localStorage.setItem('publicToken', userData.token);
            setTimeout(() => {
                Auth.setUser(userData);
            }, 1000);
        } catch (e) {
            setTimeout(() => {
                if(e?.response?.status === 422) {
                    $status.current.innerHTML = 'Неверный логин или пароль';
                } else {
                    $status.current.innerHTML = e.message;
                }
                $status.current.style.color = 'red';
                $logo.current.classList.remove(styles.spinLogo);
            }, 1000);
        }
    }

    return (
        <div className={styles.page}>
            <img ref={$logo} src={logo} className={styles.logotype} alt=''/>
            <form onSubmit={tryToAuth} className={styles.form}>
                <span ref={$status} className={styles.passstatus}> </span>
                <input ref={$login} type="text" placeholder="Логин"/>
                <input ref={$password} type="password" placeholder="Пароль"/>
                <button type='submit'>Войти</button>
            </form>
        </div>
    );
};

export default LoginPage;