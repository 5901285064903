import React, {useContext} from 'react';
import {UserContext} from "../context/UserContext";

import ContainerHeader from "./components/Main/ContainerHeader";
import AppsBlock from "./components/Main/AppsBlock";
import AppsBlockItem from "./components/Main/AppsBlockItem";

import StatsBlockItem from "./components/Main/StatsBlockItem";

import logotype from "./images/etw-logo-495057.svg";

import ic_maps from "./images/apps/maps.svg";
import ic_regmp from "./images/apps/regiomaps.svg";
import ic_chpuz from "./images/apps/childpuzzle.svg";
import ic_puzzleup from "./images/apps/puzzleup.svg";

const MainPage = () => {

    const auth = useContext(UserContext);


    const roles = [
        'Superadmin',
        'Manager'
    ];

    return (
        <div className='page'>
            {/* ШАПКА */}
            <div className='container' style={{display: 'flex', justifyContent: 'space-around'}}>
                <div>
                    <a href='/'><img src={logotype} style={{height: '50px'}} alt=""/></a>
                </div>
                <div style={{lineHeight: '25px', margin: '0 15px'}}>
                    <p>Добро пожаловать, <b style={{color: ''}}>{auth?.user?.email}</b>!</p>
                    <p style={{color: '#949494', fontWeight: 'bold', fontSize: '13px'}}>
                        {roles[auth?.user?.roleId]}
                    </p>
                </div>
                <div style={{lineHeight: '50px', margin: '0 25px'}}>
                    <button className='button-logout' onClick={auth.logoutAction}>
                        Выход
                    </button>
                </div>
            </div>

            {/* ПРИЛОЖЕНИЯ */}
            <ContainerHeader>
                Автоподпись
            </ContainerHeader>

            <AppsBlock>
                <AppsBlockItem appId={0}
                               title='World Maps'
                               img={ic_maps}
                               description='Карты мира'/>
                <AppsBlockItem appId={1}
                               title='Regio Maps'
                               img={ic_regmp}
                               description='Региональные карты'/>
                <AppsBlockItem appId={2}
                               title='Child Puzzle'
                               img={ic_chpuz}
                               description='Детские пазлы'/>
                <AppsBlockItem appId={3}
                               title='Puzzle Up'
                               img={ic_puzzleup}
                               description='Круглые пазлы'/>

            </AppsBlock>

          {auth?.user?.roleId != 1 ?
            <>

              <ContainerHeader>
                Статистика авто-подпись
              </ContainerHeader>

              <AppsBlock>
              <StatsBlockItem loadLink='/stats/asmaps'
                               title='Maps'
                               img={ic_maps}/>
                  <StatsBlockItem loadLink='/stats/aschilduniverse'
                               title='Child Puzzle'
                               img={ic_chpuz}/>
                  <StatsBlockItem loadLink='/stats/aspuzzleup'
                                  title='PuzzleUp'
                                  img={ic_puzzleup}/>
              </AppsBlock>

            </>
            : <></> }


            {/*<a href="/stats">Статистика по автоподписям</a>*/}
        </div>
    );
};

export default MainPage;
