let isAnyAppOpened;
let openedApp;

class AppInstance {
    constructor(path, width, height) {
        this.path = path;
        this.width = width;
        this.height = height;
        this.features = `resizable=no,height=${this.height},width=${this.width}`;
    }
}

const Maps = new AppInstance('/static/maps', 310, 520);
const Regio = new AppInstance('/apps/regiomaps', 330, 570);
const ChildPuzzle = new AppInstance('/apps/childpuzzle', 330, 570);
const PuzzleUp = new AppInstance('/apps/puzzleup', 330, 570);

export const apps = [
    Maps,
    Regio,
    ChildPuzzle,
    PuzzleUp
];

export function openApp(appID) {
    let thisApp = apps[appID];

    if (isAnyAppOpened) {
        if (thisApp.appID === appID) {
            if (openedApp.closed) {
                openedApp.close();
                isAnyAppOpened = false;
                openApp(appID);
            } else {
                openedApp.focus();
            }
        } else {
            openedApp.close();
            isAnyAppOpened = false;
            openApp(appID);
        }
    } else {
        openedApp = window.open(thisApp.path, '_blank', thisApp.features);
        isAnyAppOpened = true;
    }
}