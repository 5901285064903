import React from 'react';
import {apiUrl} from '../Utils';

console.log(apiUrl);

const StatsPage = () => {
    return (
        <div>
          <a href={apiUrl + '/stats/aschilduniverse'}>Статистика childUniverse</a>
        </div>
    );
};

export default StatsPage;