import axios from 'axios';
import {apiUrl} from "../Utils";


class AuthHttp {
    static async login(email, password) {
        if (!email || !password) {
            throw new Error('Не введен логин или пароль');
        }
        const response = await axios.post(apiUrl + '/auth',
            {email, password},
            {withCredentials: true}
        );
        return {
            token: response.data.token,
            userId: response.data.userId,
            email: response.data.email,
            roleId: response.data.roleId
        };
    }

    static async logout() {
        axios.post(apiUrl + '/unauth', {}, {withCredentials: true});
    }

    static async refresh() {
        const response = await axios.get(apiUrl + '/refresh', {withCredentials: true});
        const userData = {
            token: response.data.token,
            userId: response.data.userId,
            email: response.data.email,
            roleId: response.data.roleId
        };
        return userData;
    }
}

export default AuthHttp;